<template>
  <div class="danger-form-page" v-loading="pageload">
    <el-form
      ref="form"
      class="geology-form"
      :rules="formRules"
      :model="form"
      label-width="120px"
    >
      <el-tabs
        class="geology-tabs"
        v-model="activeTabName"
        style="margin-bottom: 20px"
      >
        <el-tab-pane label="基本信息" name="tabBase">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点" prop="DangerId">
                <el-select
                  style="width: 100%"
                  v-model="form.DangerId"
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="请选择、搜索监测点"
                  size="medium"
                  :remote-method="getDangerList"
                  :loading="getDangerLoading"
                >
                  <el-option
                    v-for="item in dangerSelect"
                    :key="item.Id"
                    :label="item.DangerName"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备" prop="DeviceId">
                <el-select
                  style="width: 100%"
                  v-model="form.DeviceId"
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="请选择、搜索设备"
                  size="medium"
                  :remote-method="getDeviceList"
                  :loading="getDeviceLoading"
                  @change="onDeviceChange"
                >
                  <el-option
                    v-for="item in deviceSelect"
                    :key="item.Id"
                    :label="item.DeviceName"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位名称" prop="MonitorName">
                <el-input v-model="form.MonitorName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位编号" prop="MonitorCode">
                <el-input v-model="form.MonitorCode" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测部位" prop="MonitorPosition">
                <el-input
                  v-model="form.MonitorPosition"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测方法" prop="MonitorMethod">
                <el-input v-model="form.MonitorMethod" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测级别" prop="MonitorLevel">
                <el-select
                  v-model="form.MonitorLevel"
                  size="small"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in MonitorLevelData"
                    :key="item.Id"
                    :label="item.ItemName"
                    :value="item.ItemValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="9" :offset="1">
              <el-form-item label="监测内容" prop="MonitorContent">
                <el-input v-model="form.MonitorContent" size="small"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="9" :offset="1">
              <el-form-item label="建设日期" prop="BuildTime">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.BuildTime"
                  size="small"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择建设日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="安装日期" prop="InstallTime">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.InstallTime"
                  size="small"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择安装日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="初测日期" prop="InitTime">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.InitTime"
                  size="small"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择初测日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            
            <el-col :span="9" :offset="1">
              <el-form-item label="承建单位" prop="ContractUnitId">
                <el-select
                  style="width: 100%"
                  v-model="form.ContractUnitId"
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="请选择承建单位"
                  size="medium"
                  :remote-method="getContractorList"
                  :loading="getContractorLoading"
                >
                  <el-option
                    v-for="item in contractorSelect"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
         
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="运维单位" prop="MaintainUnitId">
                <el-select
                  style="width: 100%"
                  v-model="form.MaintainUnitId"
                  filterable
                  remote
                  reserve-keyword
                  default-first-option
                  placeholder="请选择承建单位"
                  size="medium"
                  :remote-method="getMaintainList"
                  :loading="getMaintainLoading"
                  @change="onMaintainChange"
                >
                  <el-option
                    v-for="item in maintainSelect"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="运维负责人" prop="MaintainUnitUserId">
                <el-select
                  style="width: 100%"
                  v-model="form.MaintainUnitUserId"
                  placeholder="请选择运维负责人"
                  size="medium"
                >
                  <el-option
                    v-for="item in maintainUserSelect"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="19" :offset="1">
              <el-form-item label="经纬度">
                <el-col :span="7">
                  <el-form-item label-width="0px">
                    <el-input
                      v-model="form.Longitude"
                      size="small"
                      placeholder="请输入经度，例：116.393116933"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <el-form-item label-width="0px">
                    <el-input
                      v-model="form.Latitude"
                      size="small"
                      placeholder="请输入纬度，例：39.90734137"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">备注信息</el-divider>
          <el-row>
            <el-col :span="19" :offset="1">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  v-model="form.Marks"
                  size="small"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23" :offset="1">
              <el-form-item>
                <el-button type="primary" size="medium" @click="onSubmit"
                  >立即保存</el-button
                >
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-circle-close"
                  size="medium"
                  style="margin-left: 10px"
                  @click="$router.go(-1);"
                  >取消</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="图片信息" name="tabImage">
          <form-images
            :images.sync="images"
            :detailid="form.Id"
            :formtype="formtype"
          ></form-images>
        </el-tab-pane>
        <el-tab-pane label="文件信息" name="tabFile">
          <form-files
            :files.sync="files"
            :detailid="form.Id"
            :formtype="formtype"
          ></form-files>
        </el-tab-pane>
        <el-tab-pane label="预警信息接收" name="tabMessage">
          <warning-push :dangerid="form.DangerId ? form.DangerId : 0" loadtype="monitor" :monitor-info="form"></warning-push>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
import formImages from "./components/formImages";
import formFiles from "./components/formFiles";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import {
  getDangerList,
  getContractorList,
  getMaintainList,
  getMaintainUserList,
  getMonitorDetail,
  addMonitor,
  editMonitor,
} from "@/api/base";
import { getDeviceList } from "@/api/device";
import warningPush from "../warning/push"
export default {
  data() {
    return {
      pageload: false,
      activeTabName: "tabBase",
      options: regionData,
      regionValue: [],

      getDangerLoading: false,
      dangerSelect: [],

      getDeviceLoading: false,
      deviceSelect: [],

      MonitorLevelData: [],

      getContractorLoading: false,
      contractorSelect: [],

      getMaintainLoading: false,
      maintainSelect: [],

      maintainUserSelect:[],

      form: {
        DangerId: "",
        DeviceId: "",
        MonitorName: "",
        MonitorCode: "",
        MonitorPosition: "",
        MonitorMethod: "",
        MonitorContent: "",
        Longitude: "",
        Latitude: "",
        BuildTime: "",
        InstallTime: "",
        InitTime: "",
        ContractUnitId: "",
        Marks: "",
        MaintainUnitId: "",
        MonitorLevel: "",
        MaintainUnitUserId: "",
        Id: 0,
      },
      formRules: {
        DangerName: [
          { required: true, trigger: "blur", message: "请输入监测点名称" },
        ],
        DangerCode: [
          { required: true, trigger: "blur", message: "请输入监测点编号" },
        ],
        DangerType: [
          { required: true, trigger: "blur", message: "请选择灾害类型" },
        ],
        DangerLevel: [
          { required: true, trigger: "blur", message: "请选择灾害等级" },
        ],
      },
      files: [],
      images: [],
    };
  },
  components: {
    formImages,
    formFiles,
    warningPush,
  },
  props: ["formtype"],
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    getDeviceList(value) {
      this.getDeviceLoading = true;
      getDeviceList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDeviceLoading = false;
        if (resdata.code == 0) {
          this.deviceSelect = resdata.data.data;
        }
      });
    },
    getContractorList(value) {
      this.getContractorLoading = true;
      getContractorList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getContractorLoading = false;
        if (resdata.code == 0) {
          this.contractorSelect = resdata.data.data;
        }
      });
    },
    getMaintainList(value) {
      this.getMaintainLoading = true;
      getMaintainList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getMaintainLoading = false;
        if (resdata.code == 0) {
          this.maintainSelect = resdata.data.data;
        }
      });
    },
    onMaintainChange(value,initload){
      getMaintainUserList(1,100,
        {unitid:value}
      ).then((resdata) => {
        if (resdata.code == 0) {
          this.maintainUserSelect = resdata.data.data;
          initload ? '' : this.form.MaintainUnitUserId = '';
        }
      });

    },
    onDeviceChange(value){
      this.deviceSelect.forEach(item=>{
        if(item.Id == this.form.DeviceId){
          this.form.Latitude = item.Latitude;
          this.form.Longitude = item.Longitude;
        }
      })
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addMonitor({
              monitorModel: this.form,
              files: this.files,
              images: this.images,
            })
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editMonitor(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
    regionHandleChange(value) {
      if (value.length == 3) {
        this.form.Province = CodeToText[value[0]];
        this.form.City = CodeToText[value[1]];
        this.form.County = CodeToText[value[2]];
      }
    },
  },
  created() {
    this.$store.dispatch("dictionary/getMonitorLevel").then((data) => {
      this.MonitorLevelData = data;
    });
    this.getDangerList("");
    this.getContractorList("");
    this.getMaintainList("");

    if (this.formtype == "edit") {
      this.pageload = true;
      var detailId = this.$route.query.id;
      getMonitorDetail(detailId)
        .then((res) => {
          if (res.code == 0) {
            this.deviceSelect = [{
              Id:res.data.monitorModel.DeviceId,
              DeviceName:res.data.monitorModel.DeviceName,
            }]
            this.form = res.data.monitorModel;
            this.files = res.data.files;
            this.images = res.data.images;
            this.regionValue = [
              this.form.Province ? TextToCode[this.form.Province].code : "",
              this.form.City
                ? TextToCode[this.form.Province][this.form.City].code
                : "",
              this.form.County
                ? TextToCode[this.form.Province][this.form.City][
                    this.form.County
                  ].code
                : "",
            ];
            this.onMaintainChange(this.form.MaintainUnitId,true);
          }
          this.pageload = false;
        })
        .catch((errmsg) => {
          this.pageload = true;
          this.$message.error(errmsg);
        });
    }else{
      this.getDeviceList("");
    }
  },
};
</script>