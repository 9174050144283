<template>
  <div style="padding: 0px">
    <div style="text-align:right;">
      <el-button size="medium" type="primary" @click="showUploadDialod">上传图片</el-button>
    </div>
    <div class="images-list">
      <div class="images-item" v-for="(item, index) in imageArr" :key="index">
        <el-image
          style="width: 100px; height: 100px"
          :src="item.FilePath"
          fit="contain"
        >
        </el-image>
        <div class="image-name">{{item.Name}}</div>
        <i
          class="item-edit el-icon-edit"
          @click="onEdit(item, index)"
        ></i>
        <i
          class="item-close el-icon-close"
          @click="onRemove(item, index)"
        ></i>
      </div>
    </div>

    <el-dialog
      title="上传图片"
      :visible.sync="dialogFormVisible"
      width="500px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :before-close="onDialogBeforeClose"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="fileForm"
        label-width="100px"
        style="padding-top:30px;"
      >
        <el-form-item label="文件名称" prop="Name">
          <el-col :span="20">
            <el-input v-model="fileForm.Name" size="small"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="图片文件" prop="FilePath">
          <input type="hidden" v-model="fileForm.FilePath">
          <el-col :span="20">
            <single-file
            ref="singleFile"
              text="选择图片"
              :auto-upload="false"
              :show-file-list="true"
              :file-list="fileList"
              @success="uploadSuccess"
              @change="uploadChange"
            ></single-file>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" icon="el-icon-edit" size="small" @click="onUploadSubmit">确 定</el-button>
        <el-button
          size="small"
          style="margin-left: 10px"
          plain
          type="primary"
          @click="onDialogBeforeClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.images-list {
  clear: both;
  overflow: hidden;
  padding: 20px 0;
}
.images-item {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 30px 20px 0;
  float: left;
  &:hover{
    .item-close,
    .item-edit{
      display: block;
    }
  }
  .image-name{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding:3px 5px;
    background: rgba(55,55,55,.5);
    color:#fff;
    font-size: 12px;
    word-break: break-all;
  }
  .item-edit{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 16px;
    color: #0077ff;
    cursor: pointer;
    background: #fff;
    padding: 3px;
    display: none;
  }
  .item-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 22px;
    color: #ff0000;
    cursor: pointer;
    background: #fff;
    padding: 0;
    display: none;
  }
}
</style>
<script>
import { SingleFile } from "@/components/upload";
import { addMonitorImage,delMonitorImage,editMonitorImage } from "@/api/base";
export default {
  data() {
    return {
      imageArr: [],
      dialogFormVisible: false,
      fileList:[],
      fileForm: {
        Name:'',
        FilePath:'',
      },
      formRules: {
        Name: [
          { required: true, trigger: "blur", message: "请输入图片名称" },
        ],
        FilePath: [
          { required: true, trigger: "blur", message: "请选择需要上传的图片" },
        ],
      },
      dialogFormType:'add',
      activeItem:'',
    };
  },
  props: {
    title: {
      type: String,
      default: "文件上传",
    },
    images: {
      type: Array,
      default: function () {
        return [];
      },
    },
    detailid: {
      type: [String,Number],
      default: '',
    },
    formtype: {
      type: String,
      default: 'add',
    },
  },
  components: {
    SingleFile,
  },
  methods: {
    onDialogBeforeClose(){
      this.fileList = []
      this.dialogFormVisible = false;
    },
    showUploadDialod() {
      this.fileForm = {
        Name:'',
        FilePath:''
      };
      this.fileList = []
      this.dialogFormType = 'add';
      this.dialogFormVisible = true;
    },
    uploadChange(file){
      console.log(file);
      if(!this.fileForm.Name || this.fileForm.Name==''){
        this.fileForm.Name = file.name;
        this.fileForm.FilePath = file.url;
      }
    },
    uploadSuccess(file) {
      var item = {
        RelationId: this.detailid,
        Name:this.fileForm.Name,
        FilePath:file.path
      };
      if(this.formtype=='edit'){
        if(this.dialogFormType != 'edit'){
          addMonitorImage(item).then(res=>{
            if(res.code==0){
              this.$message.success("图片添加成功!");
              this.imageArr.push(item);
              this.$emit("update:images", this.imageArr);
              this.onDialogBeforeClose();

            }else{
              this.$message.error("图片添加失败!" + res.msg);
            }
          })
        }else{
          this.fileForm.FilePath = file.path;
          this.activeItem.Name = this.fileForm.Name;
          this.activeItem.FilePath = this.fileForm.FilePath;
          editMonitorImage(this.fileForm).then(res=>{
            if(res.code==0){
              this.$message.success("图片修改成功!");
              this.$emit("update:images", this.imageArr);
              this.onDialogBeforeClose();
            }else{
              this.$message.error("图片修改失败!" + res.msg);
            }
          })
        }
      }else{
        this.imageArr.push(item);
        this.$emit("update:images", this.imageArr);
        this.dialogFormVisible = false;
      }
    },
    onUploadSubmit(){
      this.$refs["form"].validate((valid) => {
        if(valid){
          this.$refs.singleFile.submit();
        }
      })
    },
    onEdit(item){
      this.fileForm = JSON.parse(JSON.stringify(item));
      this.activeItem = item;
      this.fileList = [{
        name:item.Name,
        url:item.FilePath
      }]
      this.dialogFormType = 'edit';
      this.dialogFormVisible = true;
    },
    onRemove(item, index) {
      this.$emit("update:images", this.imageArr);
      if(this.formtype=='edit'){
        delMonitorImage(item.Id).then(res=>{
          if(res.code==0){
            this.$message.success("图片删除成功!");
            this.imageArr.splice(index, 1);
          }else{
            this.$message.error("图片删除失败!" + res.msg);
          }
        })
      }
    },
  },
  created() {
  },
  watch:{
    images:function(){
      this.imageArr = this.images;
    }
  }
};
</script>